import { Card, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";

function HoverCard(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [raised, setRaised] = useState(false);


  const handleMouseOver = () => !isMobile && setRaised(true);
  const handleMouseOut = () => !isMobile && setRaised(false);
  const handleClick = () => isMobile && setRaised(prev => !prev);

  return (
    <Card
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleClick}
      raised={raised}
      sx={{ width: "100%", margin: "auto", ...props.sx }}
    >
      {props.children}
    </Card>
  );
}

export default HoverCard;
