import { Box, Typography } from "@mui/material";
import React from "react";
import ServicePageTemplate from "../ServicePageTemplate";

const AgileCoaches = () => {
    const title = "Stärk ditt team med Agila Coacher och Scrum Masters";
    const content =
        "För att stödja smidiga utvecklingsteam och främja en kultur av kontinuerlig förbättring är Agila coacher och Scrum Masters ovärderliga. Deras expertis hjälper team att bli mer effektiva och responsiva inför förändrade affärsbehov, vilket säkerställer att ditt företag kan anpassa sig och utvecklas i en ständigt föränderlig digital värld.";
    const backgroundImageUrl = "img/services/scrum-master.png";

    return (
        <>
            <ServicePageTemplate
                title={title}
                content={content}
                backgroundImageUrl={backgroundImageUrl}
                children={
                    <Box sx={{ margin: "20px", marginTop: "40px" }}>
                        <Typography variant="h6" component="div" gutterBottom>
                            Hur våra Agila coacher och Scrum Masters kan hjälpa ditt team:
                        </Typography>
                        <ul style={{ paddingLeft: "20px" }}>
                            <li>
                                <Typography variant="body1">
                                    <strong>Agil Transformation:</strong> Guidar ditt team genom agil transformation och implementering av smidiga arbetsmetoder.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Kontinuerlig Förbättring:</strong> Främjar en kultur av kontinuerlig förbättring genom regelbundna retrospektiv och feedbackloopar.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Effektivitetsökning:</strong> Hjälper team att identifiera flaskhalsar och implementera lösningar för att öka produktiviteten.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Stärkt Teamdynamik:</strong> Arbetar med teamdynamik och kommunikation för att stärka samarbetet och effektiviteten i teamet.
                                </Typography>
                            </li>
                        </ul>
                        <Typography variant="h6" gutterBottom>
                            Förbättra Ditt Team med Agil Expertis
                        </Typography>
                        <Typography variant="body1">
                            Låt våra Agila coacher och Scrum Masters transformera ditt utvecklingsteam och arbetsprocesser. Kontakta oss för att börja resan mot ökad agilitet och bättre teamprestationer.
                        </Typography>
                    </Box>
                }
            />
        </>
    );
};

export default AgileCoaches;
