import { Box, Typography } from "@mui/material";
import React from "react";

const CompanyMissionSection = () => {
    return (
        <Box my={4}>
            <Typography variant="h4" gutterBottom>
                Skräddarsydda IT-Lösningar för Framtidens Utmaningar
            </Typography>
            <Typography variant="body1" paragraph>
                Som din följeslagare i teknik och nyskapande erbjuder vi anpassade
                kompetenslösningar inom IT som möter morgondagens behov. Vi kombinerar
                expertis inom konsultuthyrning och målinriktad rekrytering för att ge
                ditt företag den skicklighet som krävs för att inte bara effektivisera
                era processer men även hålla er i täten av teknikutvecklingen. Vårt
                engagemang täcker essentiella områden som systemutveckling,
                projektledning, DevOps, cybersäkerhet, dataanalys, molntjänster, agil
                metodik, UI/UX design, och systemarkitektur - allt som är avgörande
                för att navigera i dagens och framtidens affärsvärld.
            </Typography>
            <Typography variant="h4" gutterBottom>
                Partnerskap för Framsteg
            </Typography>
            <Typography variant="body1" paragraph>
                Vårt mål är att garantera att du har tillgång till toppskiktets
                IT-talanger som kan förstärka din organisations konkurrenskraft i en
                dynamisk digital värld. Vi fokuserar på att bygga varaktiga band med
                IT-professionella, vilket ger oss en djupare insikt i IT-landskapets
                unika krav och möjliggör en perfekt sammanlänkning mellan kompetenser
                och dina specifika behov för framtida framgång.
            </Typography>
            <Typography variant="body1" paragraph>
                Årligen genomför vi över 50 rekryteringsprocesser, leder mer än 300
                intervjuer, och deltar i över 400 möten med organisationer i behov av
                specialiserad IT-kompetens. Detta engagemang utvidgar ständigt vårt
                breda nätverk av IT-professionella och förser oss med insikter och
                erfarenheter som direkt kommer våra klienter till nytta.
            </Typography>
            <Typography variant="h4" gutterBottom>
                Framtidens Möjligheter för IT-Talanger
            </Typography>
            <Typography variant="body1" paragraph>
                För IT-specialister skapar vi vägar till nya karriärmöjligheter och
                personlig utveckling. Vi ser bortom din tekniska expertis och värderar
                dina personliga ambitioner och potential för fortsatt utveckling. Vårt
                uppdrag är att stödja både organisationer och individer i deras
                strävan efter att nå sin fulla potential och tillsammans driva
                framtidens IT-landskap.
            </Typography>
            <Typography variant="body1" paragraph>
                Bli en del av vår framtidssatsning. Upptäck hur vi kan stödja din
                strävan efter innovation och teknisk framgång.
            </Typography>
        </Box>
    );
};

export default CompanyMissionSection;
