import { Box, Typography } from "@mui/material";
import React from "react";
import ServicePageTemplate from "../ServicePageTemplate";

const Cybersecurity = () => {
    const title = "Skydda din verksamhet med expertis inom Cybersäkerhet";
    const content =
        "I en tid där cyberhot ständigt utvecklas, är det kritiskt för banker, finansinstitut, telekomföretag och myndigheter att skydda känslig information och upprätthålla kundförtroende genom robusta säkerhetsåtgärder. Vårt team av cybersäkerhetsspecialister är dedikerade till att säkerställa din verksamhets säkerhet med avancerade skyddsmekanismer och proaktiva strategier.";
    const backgroundImageUrl = "img/services/technology.jpg";

    return (
        <>
            <ServicePageTemplate
                title={title}
                content={content}
                backgroundImageUrl={backgroundImageUrl}
                children={
                    <Box sx={{ margin: "20px", marginTop: "40px" }}>
                        <Typography variant="h6" component="div" gutterBottom>
                            Tjänster inom cybersäkerhet vi erbjuder:
                        </Typography>
                        <ul style={{ paddingLeft: "20px" }}>
                            <li>
                                <Typography variant="body1">
                                    <strong>Riskbedömning och Sårbarhetsanalys:</strong> Identifierar och utvärderar potentiella risker för att förhindra intrång och dataförluster.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Incidenthantering och Respons:</strong> Snabb åtgärd vid säkerhetsincidenter för att minimera skador och återställa tjänster.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Säkerhetspolicy och Compliance:</strong> Utvecklar och implementerar säkerhetspolicies som uppfyller lagkrav och branschstandarder.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Utbildning och Medvetenhet:</strong> Höjer säkerhetsmedvetenheten inom din organisation genom riktad utbildning och träning.
                                </Typography>
                            </li>
                        </ul>
                        <Typography variant="h6" gutterBottom>
                            Stärk Din Säkerhetsstrategi
                        </Typography>
                        <Typography variant="body1">
                            Låt våra cybersäkerhetsspecialister hjälpa dig att bygga en starkare, mer resilient organisation mot cyberhot. Kontakta oss för en djupgående konsultation och börja stärka din säkerhetsstrategi idag.
                        </Typography>
                    </Box>
                }
            />
        </>
    );
};

export default Cybersecurity;
