import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Box, Container, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AppBarComponent from "./components/AppBarComponent";
import Footer from './components/Footer';
import Home from "./components/Home";
import ScrollToTop from './components/ScrollToTop';
import AgileCoaches from './components/services/AgileCoaches';
import CloudSolutionsArchitectEngineer from "./components/services/CloudSolutionsArchitectEngineer";
import Cybersecurity from "./components/services/Cybersecurity";
import DataScientists from "./components/services/DataScientists";
import DevOps from './components/services/DevOps';
import Developers from './components/services/Developers';
import ProjectManagers from "./components/services/ProjectManagers";
import SolutionArchitects from "./components/services/SolutionArchitects";
import UIUXDesigners from './components/services/UIUXDesigners';
import theme from "./theme";
import NotFound from './components/NotFound';

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin]
  }
});
appInsights.loadAppInsights();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ScrollToTop />
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <AppBarComponent />
          <Container component="main" maxWidth="lg" sx={{ flexGrow: 1 }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/developers" element={<Developers />} />
              <Route path="/project-management" element={<ProjectManagers />} />
              <Route path="/devops" element={<DevOps />} />
              <Route path="/cybersecurity" element={<Cybersecurity />} />
              <Route path="/data-scientists" element={<DataScientists />} />
              <Route path="/cloud-solutions-architect-engineer" element={<CloudSolutionsArchitectEngineer />} />
              <Route path="/agile-coaches" element={<AgileCoaches />} />
              <Route path="/ui-ux" element={<UIUXDesigners />} />
              <Route path="/solution-architecture" element={<SolutionArchitects />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Container>
          <Footer />
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default withAITracking(reactPlugin, App);
