import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import clientData from "../clientData";

function ClientBanner() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const logoWidth = 150;
  const desktopLogoMargin = 60;
  const mobileLogoMargin = 15;
  const logosWidth =
    clientData.length * (logoWidth + desktopLogoMargin) - desktopLogoMargin;

  const [enlargedIndex, setEnlargedIndex] = useState(null);

  const handleLogoClick = (index) => {
    if (isMobile) {
      setEnlargedIndex(enlargedIndex === index ? null : index);
    }
  };

  const handleMouseOver = (index) => {
    if (!isMobile) {
      setEnlargedIndex(index);
    }
  };

  const handleMouseOut = () => {
    if (!isMobile) {
      setEnlargedIndex(null);
    }
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Några av våra kunder
      </Typography>
      <Box sx={{ overflowX: "hidden", display: "flex", padding: "20px 0" }}>
        <Box
          sx={{
            display: "flex",
            width: "max-content",
            animation: "scroll 120s linear infinite",
            "@keyframes scroll": {
              "0%": { transform: "translateX(0)" },
              "100%": { transform: `translateX(-${logosWidth}px)` },
            },
          }}
        >
          {[...clientData, ...clientData].map((client, index) => (
            <Box
              key={index}
              onClick={() => handleLogoClick(index)}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
              sx={{
                minWidth: `${logoWidth}px`,
                height: "100px",
                marginRight: `${desktopLogoMargin}px`,
                "&:last-child": { marginRight: "0px" },
                transition: "transform 0.3s ease-in-out",
                transform: enlargedIndex === index ? "scale(1.2)" : "scale(1)",
                [theme.breakpoints.down("md")]: {
                  marginRight: `${mobileLogoMargin}px`,
                  "&:last-child": { marginRight: `${mobileLogoMargin}px` },
                },
              }}
            >
              <img
                src={client.img}
                alt={client.title}
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}

export default ClientBanner;
