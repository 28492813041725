import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
    Box,
    Container,
    Grid,
    Link,
    Typography,
    useTheme,
} from "@mui/material";
import React from "react";

function Footer() {
    const currentYear = new Date().getFullYear();
    const theme = useTheme();

    return (
        <Box
            sx={{
                py: 6,
                backgroundColor: "#f5f5f5",
                color: theme.palette.text.secondary,
            }}
        >
            <Container maxWidth="lg">
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <Typography variant="h6" color="inherit" align="center">
                            Kontakt
                        </Typography>
                        <Link
                            href="mailto:info@jpitkonsult.se"
                            color="inherit"
                            sx={{ display: "flex", justifyContent: "center", mt: 1 }}
                        >
                            <MailOutlineIcon sx={{ mr: 1 }} /> info@jpitkonsult.se
                        </Link>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 2, textAlign: "center" }}>
                    <Typography variant="body2">
                        © {currentYear} JP IT Konsult i Stockholm AB
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}

export default Footer;
