import { Box, Typography } from "@mui/material";
import React from "react";
import ServicePageTemplate from "../ServicePageTemplate";

const DataScientists = () => {
    const title = "Förvandla data till handling med våra Dataanalytiker och Data Scientists";
    const content =
        "Med en ökande mängd tillgängliga data är det viktigt att kunna omvandla denna data till insikter som kan leda till bättre beslutsfattande, riskhantering och kundförståelse. Vårt team av dataanalytiker och data scientists använder avancerade analytiska metoder och maskininlärning för att extrahera värdefull information ur era data, vilket möjliggör smartare strategier och konkurrensfördelar.";
    const backgroundImageUrl = "img/services/data-analysis.jpg";

    return (
        <>
            <ServicePageTemplate
                title={title}
                content={content}
                backgroundImageUrl={backgroundImageUrl}
                children={
                    <Box sx={{ margin: "20px", marginTop: "40px" }}>
                        <Typography variant="h6" component="div" gutterBottom>
                            Våra tjänster inom dataanalys och data science inkluderar:
                        </Typography>
                        <ul style={{ paddingLeft: "20px" }}>
                            <li>
                                <Typography variant="body1">
                                    <strong>Dataförädling och -analys:</strong> Rengöring, strukturering och analys av rådata för att identifiera mönster, trender och insikter.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Prediktiv analys och maskininlärning:</strong> Användning av statistiska modeller och maskininlärningstekniker för att förutsäga framtida händelser baserat på historiska data.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Visualisering av data:</strong> Skapande av interaktiva dashboards och visualiseringar som gör det enkelt för beslutsfattare att förstå och agera på datadrivna insikter.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Datastyrning och kvalitetssäkring:</strong> Implementering av strategier för datastyrning för att säkerställa dataintegritet och kvalitet genom hela analysprocessen.
                                </Typography>
                            </li>
                        </ul>
                        <Typography variant="h6" gutterBottom>
                            Förbättra Din Beslutsprocess
                        </Typography>
                        <Typography variant="body1">
                            Genom att integrera dataanalys och data science i din verksamhet, kan du fatta välgrundade beslut snabbare, förbättra din riskhantering och skapa djupare kundförståelse. Kontakta oss för att utforska hur vi kan hjälpa dig att utnyttja kraften i dina data.
                        </Typography>
                    </Box>
                }
            />
        </>
    );
};

export default DataScientists;
