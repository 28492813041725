import {
    Box,
    Button,
    Grid,
    Paper,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState } from "react";


function ContactForm() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isRaised, setRaised] = useState(false);

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
    });

    const [touched, setTouched] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        message: false,
    });

    const isValidEmail = (email) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return re.test(String(email).toLowerCase());
    };

    const isValidPhone = (phone) => {
        const re = /^\+?\d{10,15}$/;
        return re.test(phone);
    };

    const MAX_LENGTHS = {
        firstName: 50,
        lastName: 50,
        email: 100,
        phone: 15,
        message: 2000,
    };

    const [errors, setErrors] = useState({});
    const [submissionStatus, setSubmissionStatus] = useState("idle");
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const handleClick = () => isMobile && setRaised(true);
    const handleMouseOver = () => !isMobile && setRaised(true);
    const handleMouseOut = () => !isMobile && setRaised(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        let error = "";

        if (value.length >= MAX_LENGTHS[name]) {
            error = `Maximalt antal tecken nått (${MAX_LENGTHS[name]}/${MAX_LENGTHS[name]})`;
        } else if (value.length >= MAX_LENGTHS[name] * 0.9) {
            error = `Du närmar dig teckengränsen (${value.length}/${MAX_LENGTHS[name]})`;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        setTouched({
            ...touched,
            [name]: true,
        });
        const validationError = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: validationError,
        }));
    };

    const validateField = (name, value) => {
        if (value.trim() === "") return "Detta fält är obligatoriskt";
        switch (name) {
            case "email":
                if (!isValidEmail(value.trim())) return "Ogiltig e-postadress";
                break;
            case "phone":
                if (!isValidPhone(value.trim())) return "Ogiltigt telefonnummer";
                break;
            default:
                break;
        }
        return "";
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const newErrors = {};
        let isFormValid = true;

        Object.keys(formData).forEach((key) => {
            const validationError = validateField(key, formData[key]);
            if (validationError) {
                newErrors[key] = validationError;
                isFormValid = false;
            }
        });

        setErrors(newErrors);

        if (isFormValid) {
            setSubmissionStatus("processing");
            try {
                const functionUrl = "/api/SubmitContactForm";
                const response = await fetch(functionUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                });

                if (!response.ok) {
                    setSubmissionStatus("error");
                    setFeedbackMessage(`Fel: Det gick inte att skicka formuläret. Försök igen senare. Statuskod: ${response.status}`);
                    return;
                }

                setSubmissionStatus("success");
                setFeedbackMessage("Tack! Ditt formulär har skickats in. Du kan förvänta dig ett svar inom två arbetsdagar.");
                setIsFormSubmitted(true);
            } catch (error) {
                setSubmissionStatus("error");
                setFeedbackMessage("Fel: Det uppstod ett problem med att skicka ditt formulär.");
                console.error("Det var ett problem med hämtoperationen:", error);
            }
        } else {
            setSubmissionStatus("error");
            setFeedbackMessage("Valideringsfel, kontrollera formuläret och försök igen.");
            console.error("Valideringsfel", newErrors);
        }
        setIsSubmitting(false);
    };

    return (
        <Paper
            id="contact-form"
            elevation={isRaised ? 8 : 1}
            onClick={handleClick}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            sx={{
                mt: 2,
                p: {
                    xs: 1,
                    sm: 6,
                },
                backgroundColor: "#FFCC80",
                borderRadius: 3,
            }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{
                    mt: 1,
                    backgroundColor: "#fff",
                    p: 3,
                    borderRadius: 2,
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)"
                }}
            >
                <Typography
                    variant="h6"
                    gutterBottom
                >
                    VILL DU ATT VI KONTAKTAR DIG?
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            disabled={isFormSubmitted}
                            fullWidth
                            id="firstName"
                            label="Förnamn"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.firstName)}
                            helperText={errors.firstName}
                            inputProps={{ maxLength: MAX_LENGTHS.firstName }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            disabled={isFormSubmitted}
                            fullWidth
                            id="lastName"
                            label="Efternamn"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.lastName)}
                            helperText={errors.lastName}
                            inputProps={{ maxLength: MAX_LENGTHS.lastName }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            disabled={isFormSubmitted}
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.email)}
                            helperText={errors.email}
                            inputProps={{ maxLength: MAX_LENGTHS.email }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            disabled={isFormSubmitted}
                            fullWidth
                            id="phone"
                            label="Telefonnummer"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.phone)}
                            helperText={errors.phone}
                            inputProps={{ maxLength: MAX_LENGTHS.phone }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            disabled={isFormSubmitted}
                            fullWidth
                            id="message"
                            label="Meddelande"
                            name="message"
                            multiline
                            rows={4}
                            value={formData.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.message)}
                            helperText={
                                errors.message ||
                                `${formData.message.length}/${MAX_LENGTHS.message}`
                            }
                            inputProps={{ maxLength: MAX_LENGTHS.message }}
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting || submissionStatus === "success"}
                    sx={{
                        mt: 3,
                        mb: 2,
                        py: 2,
                        backgroundColor: submissionStatus === "success" ? "#4CAF50" : "#FFA726",
                        color: "white", 
                        "&:hover": {
                            transform: 'scale(1.01)',
                            transition: 'transform 0.2s ease-in-out',
                            backgroundColor: submissionStatus === "success" ? "#388E3C" : "#FB8C00",
                        },
                    }}
                >
                    {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Skicka"}
                </Button>
                {feedbackMessage && (
                    <Typography
                        variant="body2"
                        sx={{
                            mt: 2,
                            color: submissionStatus === "error" ? "error.main" : "success.main",
                            animation: submissionStatus === "success" ? "fadeIn 0.5s" : "none",
                        }}
                    >
                        {feedbackMessage}
                    </Typography>
                )}
            </Box>        </Paper>
    );
}

export default ContactForm;
