import { AppBar, Container, Slide, Toolbar, Typography, useScrollTrigger } from '@mui/material';
import { Link } from 'react-router-dom';

function HideOnScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const AppBarComponent = (props) => (
    <HideOnScroll {...props}>
        <AppBar position="sticky" color="default" elevation={1}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                        <img
                            src="img/logos/jp.png"
                            alt="JP IT Konsult i Stockholm AB Logo"
                            style={{ marginRight: "20px", height: "50px" }}
                        />
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                            JP IT Konsult i Stockholm AB
                        </Typography>
                    </Link>
                </Toolbar>
            </Container>
        </AppBar>
    </HideOnScroll>
);

export default AppBarComponent;