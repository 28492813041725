import { Box, Typography } from "@mui/material";
import React from "react";
import ServicePageTemplate from "../ServicePageTemplate";

const ProjectManagers = () => {
    const title = "Effektiv projektledning för framgångsrika IT-Projekt";
    const content =
        "En erfaren projektledare är avgörande för framgången av era IT-projekt. Genom att säkerställa att projekten levereras i tid, inom budget och enligt specificerade krav, hjälper vi er att nå era affärsmål.";
    const backgroundImageUrl = "img/services/brainstorming.jpg";

    return (
        <>
            <ServicePageTemplate
                title={title}
                content={content}
                backgroundImageUrl={backgroundImageUrl}
                children={
                    <Box sx={{ margin: "20px", marginTop: "40px" }}>
                        <Typography variant="h6" component="div" gutterBottom>
                            Nyckelfördelar med vår projektledning:
                        </Typography>
                        <ul style={{ paddingLeft: "20px" }}>
                            <li>
                                <Typography variant="body1">
                                    <strong>Personlig anpassning:</strong> Strategier skräddarsydda för att passa era specifika behov och mål.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Riskhantering:</strong> Proaktiv identifiering och hantering av potentiella risker för att minimera påverkan på projektet.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Kommunikation:</strong> Effektiv samordning och kommunikation mellan alla intressenter för att säkerställa projektets framgång.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Resultatfokuserad:</strong> Vårt engagemang ligger i att leverera mätbara resultat som bidrar till er verksamhet.
                                </Typography>
                            </li>
                        </ul>
                        <Typography variant="h6" gutterBottom>
                            Kontakta oss för framgångsrik projektledning
                        </Typography>
                        <Typography variant="body1">
                            Vårt team av projektledare är redo att leda ditt nästa IT-projekt mot framgång. Kontakta oss för att börja planera för dina framtida projektframgångar.
                        </Typography>
                    </Box>
                }
            />
        </>
    );
};

export default ProjectManagers;
