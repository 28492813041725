import { Box, Typography } from '@mui/material';
import Button from "@mui/material/Button";
import React from 'react';

const HeroSection = () => (
    <Box
        sx={{
            py: { xs: 8, md: 10 },
            height: { xs: "auto", md: "500px" },
            background: `url('andrea-piacquadio.jpg') no-repeat center center`,
            backgroundSize: "cover",
            color: "black",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
            "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                    "linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
                zIndex: 1,
            },
            "& > *": {
                position: "relative",
                zIndex: 2,
            },
        }}
    >
        <Typography variant="h4" gutterBottom>
            IT-Konsulttjänster: Framtiden för Arbetsplatser
        </Typography>
        <Typography variant="body1" sx={{ mr: { md: 65 } }}>
            Vi är övertygade om att IT-konsulter spelar en avgörande roll på framtidens arbetsplatser. Genom att kombinera djup teknisk insikt med en klar förståelse för varje uppdrag, tar våra IT-konsulter din verksamhet till en helt ny nivå.
        </Typography>
        <Box my={2}>
            <a href="#contact-form" style={{ textDecoration: 'none' }}>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#FFA726",
                        color: "white",
                        '&:hover': {
                            backgroundColor: "#FB8C00",
                            transform: 'scale(1.10)',
                            transition: 'transform 0.2s ease-in-out'
                        }
                    }}
                >
                    KONTAKTA OSS
                </Button>
            </a>
        </Box>
    </Box>
);

export default HeroSection;
