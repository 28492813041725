import React from 'react';
import { Container, Typography, Button, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const images = [
  '/img/404/NotFound1.webp',
  '/img/404/NotFound2.webp',
  '/img/404/NotFound3.webp',
  '/img/404/NotFound4.webp',
];

const NotFound = () => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const randomImagePath = images[Math.floor(Math.random() * images.length)];

  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '8.4px', marginBottom: '20px' }}>
      <Typography variant={isDesktop ? 'h4' : 'h5'} gutterBottom>
        404 - Sidan hittades inte
      </Typography>
      <img src={randomImagePath} alt="Hittades inte" style={{ maxWidth: '100%', height: 'auto' }} />
      <Typography variant="subtitle1" gutterBottom>
        Sidan du letar efter kan ha tagits bort, ha fått sitt namn ändrat, eller är tillfälligt otillgänglig.
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/">
        Gå till Startsidan
      </Button>
    </Container>
  );
};

export default NotFound;
