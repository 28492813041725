import { Box, Typography } from "@mui/material";
import React from "react";
import ServicePageTemplate from "../ServicePageTemplate";

const SolutionArchitects = () => {
    const title = "Skapa framtidens IT-lösningar med Lösningsarkitekter";
    const content =
        "Dessa specialister är avgörande för att utforma IT-lösningar som inte bara möter dagens behov utan också är skalbara för framtiden, säkerställande att teknikinvesteringar ger långsiktigt värde. Våra lösningsarkitekter arbetar nära ditt team för att förstå era unika utmaningar och mål, och använder denna insikt för att skapa skräddarsydda, effektiva och hållbara tekniklösningar.";
    const backgroundImageUrl = "img/services/data_architect.png";

    return (
        <>
            <ServicePageTemplate
                title={title}
                content={content}
                backgroundImageUrl={backgroundImageUrl}
                children={
                    <Box sx={{ margin: "20px", marginTop: "40px" }}>
                        <Typography variant="h6" component="div" gutterBottom>
                            Tjänster våra Lösningsarkitekter erbjuder:
                        </Typography>
                        <ul style={{ paddingLeft: "20px" }}>
                            <li>
                                <Typography variant="body1">
                                    <strong>Arkitekturdesign och Strategi:</strong> Utvecklar omfattande arkitekturstrategier som stöder era affärsmål och tillväxt.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Teknikutvärdering och Val:</strong> Hjälper till att identifiera och välja de mest lämpliga teknikerna och plattformarna baserat på era behov.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Skalbarhet och Prestandaplanering:</strong> Ser till att lösningarna kan skala effektivt för att möta framtida krav utan onödiga kostnader.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Riskhantering och Säkerhet:</strong> Integrerar säkerhetsaspekter och riskhantering i lösningens design för att skydda mot framtida hot.
                                </Typography>
                            </li>
                        </ul>
                        <Typography variant="h6" gutterBottom>
                            Maximera Dina Teknikinvesteringar
                        </Typography>
                        <Typography variant="body1">
                            Vårt team av lösningsarkitekter är här för att säkerställa att dina teknikinvesteringar inte bara löser dagens problem utan också bygger en grund för framtiden. Kontakta oss för att utforska hur vi kan hjälpa till att transformera din tekniska infrastruktur och strategi.
                        </Typography>
                    </Box>
                }
            />
        </>
    );
};

export default SolutionArchitects;
