const clientData = [
  {
    img: "img/logos/ibinder.png",
    title: "iBinder",
    author: "",
  },
  {
    img: "img/logos/ki.png",
    title: "KI",
    author: "",
  },
  {
    img: "img/logos/lansforsakringar.png",
    title: "Länsförsäkringar",
    author: "",
  },
  {
    img: "img/logos/santander.png",
    title: "Santander",
    author: "",
  },
  {
    img: "img/logos/sg.png",
    title: "Societe Generale",
    author: "",
  },
  {
    img: "img/logos/handelsbanken.png",
    title: "Svenska Handelsbanken",
    author: "",
  },
  {
    img: "img/logos/svea.png",
    title: "Svea Ekonomi",
    author: "",
  },
];

export default clientData;
