import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import ServiceCard from "./ServiceCard";

function ServicesGrid() {
    const services = [
        {
            image: "img/services/developer.jpg",
            title: "Systemutvecklare",
            description: "För att säkerställa att era system och applikationer är moderna, säkra och anpassade efter era unika affärsbehov, är det viktigt att ha tillgång till specialiserad programmeringskompetens.",
            link: "/developers"
        },
        {
            image: "img/services/brainstorming.jpg",
            title: "Projektledare",
            description: "Effektiv projektledning är kritisk för framgången av era IT-projekt. En erfaren projektledare säkerställer att projekt levereras i tid, inom budget och enligt specificerade krav.",
            link: "/project-management"
        },
        {
            image: "img/services/it-support.jpg",
            title: "DevOps Engineers",
            description: "För att öka effektiviteten och minska tiden från utveckling till drift är DevOps Engineers ovärderliga. Deras kunskap om automatisering och integrerade systemprocesser kan accelerera leveransen av mjukvaruuppdateringar och förbättringar.",
            link: "/devops"
        },
        {
            image: "img/services/technology.jpg",
            title: "Cybersäkerhetsspecialister",
            description: "I en tid där cyberhot ständigt utvecklas, är det kritiskt för banker, finansinstitut, telekomföretag och myndigheter att skydda känslig information och upprätthålla kundförtroende genom robusta säkerhetsåtgärder.",
            link: "/cybersecurity"
        },
        {
            image: "img/services/data-analysis.jpg",
            title: "Dataanalytiker",
            description: "Med en ökande mängd tillgängliga data är det viktigt att kunna omvandla denna data till insikter som kan leda till bättre beslutsfattande, riskhantering och kundförståelse.",
            link: "/data-scientists"
        },
        {
            image: "img/services/cloud-solutions-architect.png",
            title: "Cloud Solutions Architect",
            description: "Molnteknik erbjuder skalbarhet, flexibilitet och kostnadseffektivitet. Expertis inom molnarkitektur är avgörande för att navigera i övergången till molnet och optimera molnbaserade lösningar.",
            link: "/cloud-solutions-architect-engineer"
        },
        {
            image: "img/services/scrum-master.png",
            title: "Agila coacher",
            description: "För att stödja smidiga utvecklingsteam och främja en kultur av kontinuerlig förbättring är dessa roller viktiga. De hjälper team att bli mer effektiva och responsiva inför förändrade affärsbehov.",
            link: "/agile-coaches"
        },
        {
            image: "img/services/ui-ux.webp",
            title: "UI/UX Designers",
            description: "Användarupplevelsen är avgörande för kundnöjdhet och engagemang, särskilt inom digitala tjänster. En genomtänkt design kan skilja era tjänster från konkurrenterna.",
            link: "/ui-ux"
        },
        {
            image: "img/services/data_architect.png",
            title: "Lösningsarkitekter",
            description: "Dessa specialister är avgörande för att utforma IT-lösningar som inte bara möter dagens behov utan också är skalbara för framtiden, säkerställande att teknikinvesteringar ger långsiktigt värde.",
            link: "/solution-architecture"
        }
    ];

    return (
        <>
            <Typography variant="h4" gutterBottom>
                Tjänster vi erbjuder
            </Typography>
            <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
            >
                {services.map((service, index) => (
                    <ServiceCard
                        key={index}
                        image={service.image}
                        title={service.title}
                        description={service.description}
                        link={service.link}
                    />
                ))}
            </Grid>
        </>
    );
}

export default ServicesGrid;