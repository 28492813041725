import React from 'react';
import ClientBanner from "./ClientBanner";
import CompanyMissionSection from "./CompanyMissionSection";
import ContactForm from "./ContactForm";
import HeroSection from "./HeroSection";
import InfoSection from "./InfoSection";
import ServicesGrid from "./ServicesGrid";

const Home = () => {
    return (
        <>
            <HeroSection />
            <InfoSection />
            <ClientBanner />
            <ServicesGrid />
            <ContactForm />
            <CompanyMissionSection />
        </>
    );
}

export default Home;
