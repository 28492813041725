import { Box, Typography } from "@mui/material";
import React from "react";
import ServicePageTemplate from "../ServicePageTemplate";

const CloudSolutionsArchitectEngineer = () => {
    const title = "Optimera din molnstrategi med våra Cloud Solutions Architects";
    const content =
        "Molnteknik erbjuder skalbarhet, flexibilitet och kostnadseffektivitet. Expertis inom molnarkitektur är avgörande för att navigera i övergången till molnet och optimera molnbaserade lösningar. Vårt team av Cloud Solutions Architects och Engineers är här för att leda din verksamhet genom molntransformationen och säkerställa att du utnyttjar molnets fulla potential.";
    const backgroundImageUrl = "img/services/cloud-solutions-architect.png";

    return (
        <>
            <ServicePageTemplate
                title={title}
                content={content}
                backgroundImageUrl={backgroundImageUrl}
                children={
                    <Box sx={{ margin: "20px", marginTop: "40px" }}>
                        <Typography variant="h6" component="div" gutterBottom>
                            Tjänster vi erbjuder inom molnarkitektur:
                        </Typography>
                        <ul style={{ paddingLeft: "20px" }}>
                            <li>
                                <Typography variant="body1">
                                    <strong>Molnstrategi och Rådgivning:</strong> Hjälper dig att definiera en molnstrategi som alignerar med dina affärsmål, inklusive val av molnplattform, arkitekturdesign och kostnadsoptimering.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Molnmigrering och Implementation:</strong> Planerar och genomför en smidig övergång av dina applikationer, data och infrastruktur till molnet med minimal störning för din verksamhet.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>DevOps och Automatisering i Molnet:</strong> Implementerar DevOps-praxis och automatiseringstekniker för att förbättra utvecklingsprocesser och driftsättningar i molnet.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Säkerhet och Compliance i Molnet:</strong> Säkerställer att din molninfrastruktur är säker och i linje med branschspecifika regelverk och standarder.
                                </Typography>
                            </li>
                        </ul>
                        <Typography variant="h6" gutterBottom>
                            Maximera Ditt Molnvärde
                        </Typography>
                        <Typography variant="body1">
                            Oavsett om du precis börjar din molnresa eller söker att optimera din befintliga molninfrastruktur, kan våra Cloud Solutions Architects och Engineers hjälpa dig att uppnå maximal effektivitet, säkerhet och kostnadseffektivitet. Kontakta oss för att utforska hur vi kan accelerera din molntransformation.
                        </Typography>
                    </Box>
                }
            />
        </>
    );
};

export default CloudSolutionsArchitectEngineer;
