import { Box, Typography } from "@mui/material";
import React from "react";
import ServicePageTemplate from "../ServicePageTemplate";

const UIUXDesigners = () => {
    const title = "Skapa fantastiska användarupplevelser med våra UI/UX Designers";
    const content =
        "Användarupplevelsen är avgörande för kundnöjdhet och engagemang, särskilt inom digitala tjänster. En genomtänkt design kan skilja era tjänster från konkurrenterna. Vårt team av UI/UX designers fokuserar på att skapa intuitiva, engagerande och estetiskt tilltalande digitala lösningar som uppfyller användarnas behov och överträffar deras förväntningar.";
    const backgroundImageUrl = "img/services/ui-ux.webp";

    return (
        <>
            <ServicePageTemplate
                title={title}
                content={content}
                backgroundImageUrl={backgroundImageUrl}
                children={
                    <Box sx={{ margin: "20px", marginTop: "40px" }}>
                        <Typography variant="h6" component="div" gutterBottom>
                            Tjänster inom UI/UX Design vi erbjuder:
                        </Typography>
                        <ul style={{ paddingLeft: "20px" }}>
                            <li>
                                <Typography variant="body1">
                                    <strong>Användarcentrerad Designprocess:</strong> Vi använder en iterativ designprocess som involverar användartester och feedback för att kontinuerligt förbättra användarupplevelsen.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Prototyping och Wireframing:</strong> Skapar detaljerade prototyper och wireframes som visualiserar användargränssnittet och interaktionerna i dina digitala produkter.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Grafisk Design och Branding:</strong> Utvecklar visuella identiteter som kommunicerar ditt varumärkes värden och differentierar dig från konkurrenterna.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Tillgänglighet och Användbarhet:</strong> Säkerställer att dina digitala lösningar är tillgängliga och användarvänliga för alla, oavsett funktionsförmåga.
                                </Typography>
                            </li>
                        </ul>
                        <Typography variant="h6" gutterBottom>
                            Förvandla Din Digitala Närvaro
                        </Typography>
                        <Typography variant="body1">
                            Låt våra UI/UX designers hjälpa dig att skapa digitala produkter som inte bara ser bra ut, utan också erbjuder en sömlös och minnesvärd användarupplevelse. Kontakta oss för att diskutera hur vi kan förbättra din digitala strategi.
                        </Typography>
                    </Box>
                }
            />
        </>
    );
};

export default UIUXDesigners;
