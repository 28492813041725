import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // A lighter shade of blue
    },
    secondary: {
      main: "#4CAF50", // A subtle green for accents
    },
    background: {
      default: "white", // A light grey background
    },
    text: {
      primary: "#333333", // Darker text for better readability
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: "white", // Set all tabs to white
          "&:hover": {
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.6)", // More pronounced shadow effect on hover
            opacity: 1, // Keep full opacity on hover
          },
          "&.Mui-selected": {
            color: "white", // Keep the active tab white
            fontWeight: "bold", // Bold for the active tab
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "white",
        },
      },
    },
  },
});

export default theme;
