import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const InfoSection = () => (
    <Box>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Box my={4}>
                    <Box my={4}>
                        <Typography variant="h4" gutterBottom>
                            Står du inför brådskande IT-utmaningar?
                        </Typography>
                        <Typography variant="body1">
                            Behovet av expertis kan uppstå oväntat, oavsett om det beror på
                            att en nyckelperson har lämnat eller ett pressande deadline närmar
                            sig. Vi är här för att erbjuda snabb tillgång till våra erfarna
                            IT-specialister och ledare. Vårt team är redo att snabbt hoppa in,
                            anpassa sig och leverera de resultat du behöver.
                        </Typography>
                    </Box>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Din partner i innovation och IT-lösningar
                    </Typography>
                    <Typography variant="body1">
                        Vi tar din verksamhet till nästa nivå med hjälp av våra
                        kvalificerade IT-konsulter. De är inte bara tekniskt skickliga utan
                        förstår även djupet i dina projekt och utmaningar. Oavsett om det
                        gäller daglig drift, en omfattande transformationsprocess eller
                        specifika utvecklingsprojekt, är våra konsulter beredda att möta
                        dina behov. Vi är passionerade om att skapa den perfekta matchningen
                        och säkerställer att vi hittar rätt specialist för just dina
                        utmaningar.
                    </Typography>
                </Box>
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Bekymmersfri IT-konsultation
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Att hitta rätt kompetens kan vara tidskrävande och utmanande. Vi gör det enkelt för dig att snabbt anlita den IT-konsult du behöver, när du behöver dem. Processen ska vara smidig och rakt på sak. Genom att förena din insikt om företagets behov med vår djupa förståelse för IT-konsultation, gör vi industrin enklare och mer tillgänglig för alla.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Låt oss hjälpa dig att navigera i IT-världen med förtroende och enkelhet.
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box
                    sx={{
                        my: 4,
                        height: 1029,
                        backgroundImage: `url('img/hero/office.jpg')`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        borderRadius: "4px",
                        position: "relative",
                        display: { xs: "none", md: "block" }, 
                        "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage:
                                "linear-gradient(to right, rgba(255,255,255,0.9), rgba(255,255,255,0))", 
                            zIndex: 1,
                        },
                        "& > *": {
                            position: "relative",
                            zIndex: 2,
                        },
                    }}
                />
            </Grid>
        </Grid>
    </Box>
);

export default InfoSection;
