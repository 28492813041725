import { Box, Typography } from "@mui/material";
import React from "react";
import ServicePageTemplate from "../ServicePageTemplate";

const Developers = () => {
    const title = "Specialiserad programmeringskompetens för dina affärsbehov";
    const content =
        "I en värld där teknik ständigt utvecklas, är det avgörande för företag inom alla branscher att hålla sina system och applikationer moderna, säkra och anpassade efter unika affärsbehov. Vårt team av systemutvecklare och programmerare är här för att säkerställa just detta. Med djupgående expertis och en passion för teknik, strävar vi efter att leverera lösningar som inte bara möter, utan överträffar dina förväntningar.";
    const backgroundImageUrl = "img/services/developer.jpg";

    return (
        <>
            <ServicePageTemplate
                title={title}
                content={content}
                backgroundImageUrl={backgroundImageUrl}
                children={
                    <Box sx={{ margin: "20px", marginTop: "40px" }}>
                        <Typography variant="h6" component="div" gutterBottom>
                            Hur vi kan stärka din verksamhet:
                        </Typography>
                        <ul style={{ paddingLeft: "20px" }}>
                            <li>
                                <Typography variant="body1">
                                    <strong>Skalbara och Anpassningsbara Lösningar:</strong> Våra utvecklare är skickliga på att skapa flexibla system som kan växa och anpassa sig tillsammans med din verksamhet.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Modernisering av System:</strong> Vi hjälper dig att modernisera och uppdatera dina befintliga system för att öka säkerheten och effektiviteten, samtidigt som vi säkerställer en sömlös användarupplevelse.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Säkerhetsinriktad Utveckling:</strong> Med fokus på de senaste säkerhetsstandarderna ser vi till att dina applikationer och data skyddas mot externa hot.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Teknisk Rådgivning:</strong> Vårt team fungerar inte bara som utvecklare utan även som tekniska rådgivare, redo att guida dig genom tekniklandskapet för att säkerställa att du gör de bästa möjliga tekniska valen för din organisation.
                                </Typography>
                            </li>
                        </ul>
                        <Typography variant="h6" gutterBottom>
                            Partnerskap för Framtiden
                        </Typography>
                        <Typography variant="body1">
                            Låt oss bli din teknikpartner. Genom att samarbeta med oss får du tillgång till en pool av begåvade utvecklare som är dedikerade till att hjälpa din verksamhet att nå nya höjder. Kontakta oss idag för att diskutera hur vi kan hjälpa dig att navigera i den digitala världen.
                        </Typography>
                    </Box>
                }
            />
        </>
    );
};

export default Developers;
