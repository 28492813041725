import { Box, Typography } from "@mui/material";
import React from 'react';
import CompanyMissionSection from "./CompanyMissionSection";
import ContactForm from "./ContactForm";

const ServicePageTemplate = ({ title, content, backgroundImageUrl, children }) => {
    return (
        <>
            <Box
                sx={{
                    py: { xs: 8, md: 10 },
                    height: { xs: "auto", md: "500px" },
                    background: `url(${backgroundImageUrl}) no-repeat center center`,
                    backgroundSize: "cover",
                    color: "black",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    position: "relative",
                    "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background:
                            "linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
                        zIndex: 1,
                    },
                    "& > *": {
                        position: "relative",
                        zIndex: 2,
                    },
                }}
            >
                <Typography variant="h4" gutterBottom sx={{
                    mr: { md: 65 },
                    overflowWrap: 'break-word',
                    hyphens: 'auto',
                    WebkitHyphens: 'auto', // For Safari
                    MozHyphens: 'auto', // For Firefox
                }}>
                    {title}
                </Typography>
                <Typography variant="body1" sx={{ mr: { md: 65 } }}>
                    {content}
                </Typography>
            </Box>
            {children}
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                </Typography>
                <Typography variant="body1">
                </Typography>
                <ContactForm />
                <CompanyMissionSection />
            </Box>
        </>
    );
};

export default ServicePageTemplate;
