import { Button, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import HoverCard from "./HoverCard";

function ServiceCard({ image, title, description, link }) {
    return (
        <Grid item xs={4} sm={4} md={4}>
            <HoverCard>
                <CardMedia
                    sx={{ height: 140 }}
                    image={image}
                    title={title}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Link to={link} style={{ textDecoration: 'none' }}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            sx={{
                                ':hover': {
                                    transform: 'scale(1.05)',
                                    transition: 'transform 0.2s ease-in-out'
                                }
                            }}
                        >
                            Läs mer
                        </Button>
                    </Link>
                </CardActions>
            </HoverCard>
        </Grid>
    );
}

export default ServiceCard;
