import { Box, Typography } from "@mui/material";
import React from "react";
import ServicePageTemplate from "../ServicePageTemplate";

const DevOps = () => {
    const title = "Accelerera din utveckling med erfarna DevOps Engineers";
    const content =
        "För att öka effektiviteten och minska tiden från utveckling till drift är DevOps Engineers ovärderliga. Deras expertis inom automatisering och integrering av systemprocesser kan dramatiskt påskynda leveransen av mjukvaruuppdateringar och förbättringar, vilket säkerställer att ditt företag kan anpassa sig snabbt i en ständigt föränderlig digital värld.";
    const backgroundImageUrl = "img/services/it-support.jpg";

    return (
        <>
            <ServicePageTemplate
                title={title}
                content={content}
                backgroundImageUrl={backgroundImageUrl}
                children={
                    <Box sx={{ margin: "20px", marginTop: "40px" }}>
                        <Typography variant="h6" component="div" gutterBottom>
                            DevOps-tjänster vi erbjuder:
                        </Typography>
                        <ul style={{ paddingLeft: "20px" }}>
                            <li>
                                <Typography variant="body1">
                                    <strong>Automatisering av Pipelines:</strong> Vi implementerar CI/CD-pipelines för att automatisera testning och distribution, vilket minskar risken för mänskliga fel och ökar hastigheten i leveransprocessen.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Cloud Integration och Management:</strong> Optimerar användningen av molntjänster för skalbarhet, flexibilitet och kostnadseffektivitet i din IT-infrastruktur.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Systemövervakning och Logganalys:</strong> Implementerar verktyg för att övervaka systemprestanda i realtid och analysera loggar för snabb problemidentifiering och åtgärd.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Infrastruktur som Kod (IaC):</strong> Använder kod för att hantera och provisionera infrastruktur, vilket bidrar till högre systemkonsekvens och effektivare hantering.
                                </Typography>
                            </li>
                        </ul>
                        <Typography variant="h6" gutterBottom>
                            Framtidssäkra Din IT-Operation
                        </Typography>
                        <Typography variant="body1">
                            Med vårt team av DevOps Engineers kan du förvänta dig en smidigare, snabbare och mer effektiv utvecklingscykel, samt robusta och skalbara lösningar. Kontakta oss för att se hur vi kan transformera din utvecklings- och driftsmiljö.
                        </Typography>
                    </Box>
                }
            />
        </>
    );
};

export default DevOps;
